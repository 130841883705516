import { Controller } from 'stimulus'

import Swiper, { Autoplay, Navigation, Pagination, EffectFade } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, Navigation, Pagination, EffectFade])

export default class extends Controller {
  static targets = ['next', 'prev', 'pagination', 'container']

  connect() {
    new Swiper(this.containerTarget, {
      loop: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      speed: 500,
      slidesPerView: 1,
      autoplay: {
        delay: 10000,
      },
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      },
      pagination: {
        el: this.paginationTarget,
        clickable: true,
      },
    })
  }
}
