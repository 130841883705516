import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import SweetScroll from 'sweet-scroll';

import Alpine from 'alpinejs'
import 'tailwindcss/tailwind.css'

import AOS from 'aos';
import 'aos/dist/aos.css';


import 'controllers'

import "@fontsource/titillium-web/400.css"
import "@fontsource/titillium-web/600.css"
import "@fontsource/titillium-web/700.css"
import "@fontsource/open-sans/400.css"
import "@fontsource/open-sans/600.css"

require.context('../images', true)

Rails.start()
ActiveStorage.start()
Turbolinks.start()
Alpine.start()

document.addEventListener('turbolinks:load', () => {
  const sweetScroll = new SweetScroll({ /* some options */ });
  AOS.init();
}, false);